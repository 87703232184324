/** @jsxImportSource @emotion/react */

import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";
import logger from "hooks/lib/logger";
import { rewriteErrorMessage } from "./rewriteErrorMessage";

export type ConvenientErrorObject =
  | ApolloError
  | GraphQLError
  | { message: string }
  | Error;

export type ConvenientError = ConvenientErrorObject | string | null | undefined;

export function isConvenientErrorObject(
  error: any | unknown
): error is ConvenientErrorObject {
  return (
    error instanceof ApolloError ||
    error instanceof GraphQLError ||
    error instanceof Error ||
    typeof error === "string" ||
    ("message" in error && typeof error.message === "string")
  );
}

// We make this easy to use by accepting as many formats of errors as possible
export default function errorMessagesFromErrors(
  error:
    | ConvenientError
    | ConvenientError[]
    | readonly ConvenientError[]
    | ConvenientError[][]
): string[] {
  return extractErrorMessagesFromErrors(error).map(rewriteErrorMessage);
}

export function userErrorMessagesFromErrors(
  error:
    | ConvenientError
    | ConvenientError[]
    | readonly ConvenientError[]
    | ConvenientError[][]
): string[] {
  return extractUserErrorMessagesFromErrors(error).flatMap(rewriteErrorMessage);
}

function extractUserErrorMessagesFromErrors(
  error:
    | ConvenientError
    | ConvenientError[]
    | readonly ConvenientError[]
    | ConvenientError[][]
): string[] {
  if (!error) {
    return [];
  }

  if (typeof error === "string") {
    return [error];
  }

  if (error instanceof ApolloError) {
    const errors = [
      ...error.graphQLErrors.map(
        (gqlError: GraphQLError & { userMessage?: string }) =>
          "userMessage" in gqlError && gqlError.userMessage
            ? gqlError.userMessage
            : []
      ),
    ].flat();
    return errors;
  }

  // our server adds a `userMessage` property to errors, which goes beyond the GraphQL spec
  type ErrorThatOurServerReturns = { userMessage?: string };
  if ((error as ErrorThatOurServerReturns).userMessage) {
    const userMessage = (error as ErrorThatOurServerReturns).userMessage;
    if (userMessage) {
      return [userMessage];
    }
  }

  if (error instanceof GraphQLError || error instanceof Error) {
    return [];
  }

  if (Array.isArray(error)) {
    return (error as ConvenientError[]).flatMap(errorMessagesFromErrors);
  }

  if ("message" in error) {
    return [];
  }

  logger.info("Couldn't display error to user", error);
  logger.error(new Error("couldn't display error to user"));

  return [];
}

function extractErrorMessagesFromErrors(
  error:
    | ConvenientError
    | ConvenientError[]
    | readonly ConvenientError[]
    | ConvenientError[][]
): string[] {
  if (!error) {
    return [];
  }

  if (typeof error === "string") {
    return [error];
  }

  if (error instanceof ApolloError) {
    const errors = [
      ...error.graphQLErrors.map(
        (gqlError: GraphQLError & { userMessage?: string }) =>
          "userMessage" in gqlError && gqlError.userMessage
            ? gqlError.userMessage
            : gqlError.message
      ),
    ];
    if (error.networkError) {
      errors.push(error.networkError.toString());
    }
    return errors;
  }

  // our server adds a `userMessage` property to errors, which goes beyond the GraphQL spec
  type ErrorThatOurServerReturns = { userMessage?: string };
  if ((error as ErrorThatOurServerReturns).userMessage) {
    const userMessage = (error as ErrorThatOurServerReturns).userMessage;
    if (userMessage) {
      return [userMessage];
    }
  }

  if (error instanceof GraphQLError) {
    return [error.message] ?? [];
  }

  if (error instanceof Error) {
    return [error.toString()];
  }

  if (Array.isArray(error)) {
    return (error as ConvenientError[]).flatMap((e) =>
      errorMessagesFromErrors(e)
    );
  }

  if ("message" in error) {
    return [error.message];
  }

  logger.info("Couldn't display error to user", error);
  logger.error(new Error("couldn't display error to user"));

  return [];
}
