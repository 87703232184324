/** @jsxImportSource @emotion/react */

import { MutationResult } from "@apollo/client";
import { rewriteErrorMessage } from "./rewriteErrorMessage";

export type ErrorableMutationResult<M = any> = {
  [K in keyof MutationResult<M>["data"]]: {
    success: boolean;
    errorMessage?: string | null;
  };
} & MutationResult<M>["data"];

export function errorMessagesFromData<T>(
  data?: ErrorableMutationResult<T> | null
): string[] {
  if (!data) {
    return [];
  }

  return Object.values(data).flatMap((innerData: any) => {
    if (!innerData || !("errorMessage" in innerData)) {
      return [];
    }
    const { success, errorMessage } = innerData;

    // Note: since we're often not fetching `success` in our mutations, it's important to just assume that
    // `errorMessage` being populated means a failure if `success` has not been fetched.
    // Context: https://spruce.slack.com/archives/CCBNU388L/p1684853515719779
    if (!success && errorMessage) {
      return [rewriteErrorMessage(errorMessage as string)];
    }
    return [];
  });
}
