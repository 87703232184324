import { ApolloError, FetchResult } from "@apollo/client";
import {
  ErrorableMutationResult,
  errorMessagesFromData,
} from "./errorMessagesFromData";
import errorMessagesFromErrors, {
  ConvenientError,
} from "./errorMessagesFromErrors";

import { GraphQLError } from "graphql";
import logger from "../hooks/lib/logger";

export class GroupByKeyError extends Error {
  groupingKey = "";
  constructor(message: string, key: string, cause: Error | null = null) {
    super(message, cause ? makeCause(cause) : {});
    Object.setPrototypeOf(this, new.target.prototype);
    this.groupingKey = key;
  }
}
export class UserReportedBugError extends GroupByKeyError {
  groupingKey = "";
  comment = "";
  constructor(message: string, key: string, comment?: string) {
    super(message, key);
    Object.setPrototypeOf(this, new.target.prototype);
    this.groupingKey = key;
    this.comment = comment ?? "";
  }
}
export class GraphQLDataMissingError extends Error {
  ignoredByOverlay = true;
  retry?: () => void;
  constructor(message?: string, retry?: () => void) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.retry = retry;
  }
}
export class ReportedGraphQLError extends Error {
  operationName = "";
  isInputError = false;
  originalError: Error | null = null;
  constructor(
    originalError: Error,
    operationName: string,
    isInputError?: boolean
  ) {
    super(originalError.message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.operationName = operationName;
    this.originalError = originalError;
    this.isInputError = !!isInputError;
  }
}

export class AuthenticationError extends Error {
  ignoredByOverlay = true;
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class AuthorizationError extends Error {
  ignoredByOverlay = true;
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class ServerError extends Error {
  ignoredByOverlay = true;
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class NotFoundError extends Error {
  ignoredByOverlay = true;
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class UpgradeRequiredError extends Error {
  ignoredByOverlay = true;
  upgradeURL = "";
  constructor(message: string, upgradeURL: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.upgradeURL = upgradeURL;
  }
}

export function errorFromApolloError(error: ApolloError) {
  if (error.networkError) {
    return new ServerError();
  }

  const firstGraphQLError = error.graphQLErrors[0] as GraphQLError & {
    type:
      | "INTERNAL"
      | "NOT_AUTHENTICATED"
      | "NOT_AUTHORIZED"
      | "NOT_FOUND"
      | string;
  };
  if (!firstGraphQLError) {
    return new ServerError();
  }

  switch (firstGraphQLError.type) {
    case "INTERNAL":
      return new ServerError();
    case "NOT_AUTHENTICATED":
      return new AuthenticationError();
    case "NOT_AUTHORIZED":
      return new AuthorizationError();
    case "NOT_FOUND":
      return new NotFoundError();
    default:
      return new ServerError();
  }
}

export function parseErrorMessages<T>(
  data?: ErrorableMutationResult<T> | null,
  errorOrErrors?:
    | ConvenientError
    | ConvenientError[]
    | readonly ConvenientError[]
    | ConvenientError[][]
): string[] {
  return [
    ...errorMessagesFromData(data),
    ...errorMessagesFromErrors(errorOrErrors),
  ];
}

export function makeCause(error: any): { cause: Error } | undefined {
  if (error instanceof Error) {
    return { cause: error };
  }
  logger.warn("Error cause is not an instance of `Error`: ", error);
  return undefined;
}

export const throwParsedErrorIfGraphQLRequestFailed = <T>(
  response: FetchResult<
    ErrorableMutationResult<T>,
    Record<string, any>,
    Record<string, any>
  >
) => {
  const errors = parseErrorMessages(response.data, response.errors);

  if (errors && errors.length > 0) {
    throw new Error(errors[0]);
  }
  return response;
};
