// NOTE: we want to limit the number of dependencies in this file, since it's
// loaded very early, and it's important that this never breaks (since it's doing
// the error reporting!)

import {
  GroupByKeyError,
  ReportedGraphQLError,
  UserReportedBugError,
} from "./lib/errors";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import logger from "./hooks/lib/logger";
import { isWindowInactive } from "./hooks/use-is-window-active";
import { adminBaseURL } from "./lib/adminBaseURL";
import appVersion from "./lib/appVersion";
import { generateWeakUUID } from "./lib/generateWeakUUID";
import isElectron from "./lib/isElectron";
import isEnvironment from "./lib/isEnvironment";
import isWindowVisible from "./lib/isWindowVisible";

export default function bootstrapBugsnag() {
  let networkErrorsInTheLastTwoMinutes: Date[] = [];

  const currentAPIEnvironment = window.REACT_APP_API_ENVIRONMENT;

  // "monolith" must be included in order for the onError callback to be executed so that we can print errors to the console
  const enabledReleaseStages: (typeof window.REACT_APP_API_ENVIRONMENT)[] =
    window.REACT_APP_ENVIRONMENT === "dev"
      ? []
      : ["monolith", "dev", "staging", "prod"];

  Bugsnag.start({
    apiKey: "d541879b1a50890eb3e495d438a8d646",
    plugins: [new BugsnagPluginReact()],
    appVersion: appVersion,
    releaseStage: currentAPIEnvironment,
    enabledReleaseStages,

    // set Bugsnag logger to null for local development to reduce console noise
    logger: window.REACT_APP_ENVIRONMENT === "dev" ? null : undefined,

    // we proxy Bugsnag requests in the browser so that they aren't blocked by default by content blockers
    // however, that type of blocking isn't as common with desktop apps
    ...(isElectron
      ? {}
      : {
          endpoints: {
            notify: `${window.REACT_APP_APP_BASE_URL}/bugsnag/notify`,
            sessions: `${window.REACT_APP_APP_BASE_URL}/bugsnag/sessions`,
          },
        }),

    // this was necessary to prevent memory leaks
    trackInlineScripts: false,

    maxBreadcrumbs: 100,
    onBreadcrumb: (breadcrumb) => {
      // We try-catch to try to keep the browser console cleaner: https://spruce.slack.com/archives/C03QN07TGJG/p1678715167046869
      try {
        // This obscures potential PHI: Bugsnag is collecting the text of HTML elements
        // https://app.asana.com/0/1183444582742194/1199338053869254
        if (breadcrumb?.metadata && "targetText" in breadcrumb.metadata) {
          breadcrumb.metadata.targetText = breadcrumb.metadata.targetText
            ?.replace(/[a-z]/g, "x")
            .replace(/[A-Z]/g, "X");
        }
      } catch (e) {
        // do nothing
      }
    },
    onError: (event) => {
      try {
        let metadata = {};
        // NOTE(jon): Since NetworkErrors are out of our control, the best we can do is just be aware of it
        if (
          event.originalError instanceof Error &&
          (event.originalError.message.includes("NetworkError") ||
            event.originalError.message === "Failed to fetch")
        ) {
          networkErrorsInTheLastTwoMinutes = [
            ...networkErrorsInTheLastTwoMinutes,
            new Date(),
          ].filter((date) => Date.now() - date.getTime() < 60 * 2 * 1000);

          metadata = {
            networkErrorsInTheLastTwoMinutes:
              networkErrorsInTheLastTwoMinutes.length,
          };

          // Report the first error, but don't report more for the remainder of the session
          // in order to limit noise
          if (networkErrorsInTheLastTwoMinutes.length === 1) {
            // the plan is to ignore this permanently in bugsnag, but let it be searchable by account ID
            event.groupingHash = `skip_inbox-network_error`;
          } else {
            logger.log(
              `not sending network error to Bugsnag: ${event.originalError.message}`,
              metadata
            );
            return false;
          }
        }

        event.addMetadata("session", {
          windowInnerSize: `${window.innerWidth} x ${window.outerWidth}`,
          inactive: isWindowInactive(),
          visible: isWindowVisible(),
          referrer: document.referrer,
        });

        // const client = getClient();
        // const networkStatusToLabelMap: { [k in NetworkStatus]: string } = {
        //   [NetworkStatus.ready]: "ready",
        //   [NetworkStatus.error]: "error",
        //   [NetworkStatus.fetchMore]: "fetchMore",
        //   [NetworkStatus.loading]: "loading",
        //   [NetworkStatus.poll]: "poll",
        //   [NetworkStatus.refetch]: "refetch",
        //   [NetworkStatus.setVariables]: "setVariables",
        // };
        // event.addMetadata("network", {
        //   unfinishedApolloQueries:
        //     // @ts-ignore
        //     [...client.queryManager.queries.values()]
        //       .filter((q) => q.networkStatus !== NetworkStatus.ready)
        //       .reduce(
        //         (
        //           status: { [key: string]: string[] } | undefined,
        //           query: {
        //             networkStatus: NetworkStatus;
        //             observableQuery: null | ObservableQuery;
        //           }
        //         ) => {
        //           if (!query.observableQuery?.queryName) {
        //             return status;
        //           }
        //           const label = networkStatusToLabelMap[query.networkStatus];
        //           if (!status) {
        //             status = {};
        //           }
        //           if (!status[label]) {
        //             status[label] = [];
        //           }
        //           if (
        //             !status[label].includes(query.observableQuery.queryName)
        //           ) {
        //             status[label].push(query.observableQuery.queryName);
        //           }
        //           return status;
        //         },
        //         {}
        //       ),
        // });

        // NOTE(jon): this enables pausing the debugger when an error is encountered, so that we can fix it in the moment, ideally
        // The theory is that we want to have zero errors. So we either convert known errors to breadcrumbs, or we fix them.
        const shouldStopOnError =
          // need to check NODE_ENV because it's possible to be running the web app locally,
          // yet targeting the staging backend
          window.REACT_APP_ENVIRONMENT === "dev";

        if (shouldStopOnError) {
          // Feel free to exclude specific errors that are common and unfixable from pausing the debugger
          if (
            event.originalError &&
            // excluded TypeError to improve developer experience since are super common in development, when the type checker fails the but build continues
            event.originalError.errorClass !== "TypeError" &&
            event.originalError.name !== "TypeError" &&
            // excluded because this is a compiler error, and breaking here slows down development
            event.originalError.name !== "ReferenceError"
          ) {
            const originalErrorMessage =
              typeof event.originalError.message === "string"
                ? event.originalError.message
                : "";

            if (
              // excluded because this is a known error that we can't work around at the moment: https://app.asana.com/0/search/1200047179873207/1199943622033863
              originalErrorMessage.includes(
                `Variable "$inboxID" of required type "ID!" was not provided.`
              ) ||
              originalErrorMessage.startsWith("Please sign in to continue.") ||
              originalErrorMessage.startsWith(
                "dropping row with duplicate key"
              ) ||
              originalErrorMessage.includes("took exceedingly long") ||
              originalErrorMessage.includes("diagnostic error") ||
              // PubNub in the monolith environment doesn't yet work
              (isEnvironment("monolith") &&
                [
                  "channel subscription grant failed",
                  "Missing data from registerDeviceForPush mutation",
                ].some((message) => originalErrorMessage.includes(message)))
            ) {
              // No-op.
            } else {
              debugger;
            }
          }
        }

        if (event.originalError instanceof UserReportedBugError) {
          const uuid = generateWeakUUID();
          event.groupingHash = `group_by_key-${event.originalError.groupingKey}`;
          // Context is the display name of the event in the Bugsnag dashboard
          event.context = `${event.originalError.message} (${event.originalError.groupingKey}) ${uuid}`;

          // eslint-disable-next-line no-console
          console.log(`sending bug report with identifier: ${uuid}`);

          const bugsnagUserURL = `https://app.bugsnag.com/spruce-health/care-messenger-web-1/errors?filters[event.since][0]=7d&filters[user.id][0][type]=eq&filters[user.id][0][value]=${
            event.getUser().id
          }`;
          const bugsnagSearchURL = `https://app.bugsnag.com/spruce-health/care-messenger-web-1/errors?filters[event.since][0][type]=eq&filters[event.since][0][value]=1d&filters[app.context][0][type]=eq&filters[app.context][0][value]=${uuid}`;
          const user = event.getUser().email || event.getUser().id || "unknown";

          const slackInput = {
            unfurl_links: false,
            unfurl_media: false,
            username: "Bug Reporter",
            icon_emoji: ":bug:",
            blocks: [
              {
                type: "header",
                text: {
                  type: "plain_text",
                  text: "New user-filed bug report",
                },
              },
              {
                type: "section",
                fields: [
                  {
                    type: "mrkdwn",
                    text: `*Comment:*\n${event.originalError.comment}`,
                  },
                ],
              },
              {
                type: "section",
                fields: [
                  {
                    type: "mrkdwn",
                    text: `*User:*\n${user}`,
                  },
                  {
                    type: "mrkdwn",
                    text: `<${adminBaseURL}/account/${
                      event.getUser().id
                    }|User in Admin Panel>`,
                  },
                ],
              },
              {
                type: "section",
                fields: [
                  {
                    type: "mrkdwn",
                    text: `*Version:*\n${appVersion}`,
                  },
                  {
                    type: "mrkdwn",
                    text: `*Client Environment:*\n${window.REACT_APP_API_ENVIRONMENT}`,
                  },
                ],
              },
              {
                type: "section",
                fields: [
                  {
                    type: "mrkdwn",
                    text: `<${bugsnagSearchURL}|Search for error in Bugsnag>`,
                  },
                  {
                    type: "mrkdwn",
                    text: `<${bugsnagUserURL}|Search for user in Bugsnag>`,
                  },
                ],
              },
            ],
          };

          fetch(
            // Post to #bugsnag-webapp channel
            "https://hooks.slack.com/services/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",
            {
              method: "POST",
              // Content-Type triggers CORS OPTIONS request, which Slack doesn't handle
              // headers: { "Content-Type": "application/json" },
              body: JSON.stringify(slackInput),
            }
          ).catch((error) => {
            logger.error(error);
          });
        } else if (event.originalError instanceof GroupByKeyError) {
          event.groupingHash = `group_by_key-${event.originalError.groupingKey}`;
          // Context is the display name of the event in the Bugsnag dashboard
          event.context = `${event.originalError.message} (${event.originalError.groupingKey})`;
        } else if (event.originalError instanceof ReportedGraphQLError) {
          // NOTE(jon): we want to report "invalid input" (ex: missing variables) GraphQL errors to Bugsnag because it usually means we're doing something wrong
          event.groupingHash = event.originalError.isInputError
            ? `invalid_input-${event.originalError.operationName}`
            : `graphqlerror-${event.originalError.operationName}`;

          // NOTE(jon): the signal:noise ratio is too low for these types of networking errors,
          // so instead we make them breadcrumbs
          if (!event.originalError.isInputError) {
            return false;
          }

          // Context is the display name of the event in the Bugsnag dashboard
          event.context = `GraphQLError: Invalid input to operation ${event.originalError.operationName}: ${event.originalError.message}`;

          // NOTE(jon): this will hopefully help us root cause some queries
          // that are missing variables such as the InboxQuery
          // https://app.asana.com/0/1183444582742194/1199901417206175
          if (!isEnvironment("prod") && event.originalError.isInputError) {
            debugger;
          }

          // NOTE(jon): this is in response to seeing errors in Bugsnag show up as
          // file unknown and line unknown, and having them all be grouped together as one
        } else if (
          event.errors.find(
            (err) => !err.stacktrace || err.stacktrace.length === 0
          )
        ) {
          event.groupingHash = event.originalError.message;
          event.context = event.originalError.message;
        }
      } catch (err) {
        // NOTE(jon): if our logic is throwing an error, we should fix it right away. That's why this debugger statement is here.
        debugger;

        // eslint-disable-next-line no-console
        console.error(
          new Error("experienced error while trying to submit bug report: "),
          err
        );
      }
    },
  });
}
